import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Typography,
} from '@mui/material'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { useTheme } from '@mui/system'

type PhotoContentItem = {
  imgUrl: string
  title: string
  subTitle: string
  href: string
}

const photoContent: PhotoContentItem[] = [
  {
    title: 'Forberedelse',
    subTitle: 'Se optakten.',
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mG8ZI_7FCG54c2_7Ugq6HZSzA0YWQPbpzG5Hlp3t8Gp-QRwaHUHpXgrh4JJgdJ_ocEyNG4hCNoytcyc1ey23Pn_NFV9Cu2sBxLB62Kcw6HXtsCkooxaVYgM1tUIU-4G2sdhJRB715rCvaWDAeWS9D-0AZ4BcOfLmmB7vqXWlEzVq1YmBzwc0G3xnTU7pY0og7?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!27513&parId=BB74063D28EA3BAD!27509&authkey=!ANcxtm19G49_UtY',
  },
  {
    title: 'Kirke',
    subTitle: 'Se billeder fra kirken',
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mJrMVJGk7jVqKxdzW8RJfAhU6XtfPLhZ-c926ZISPY4rORWujivzn5tpz6uSTEivxBkQpsmaiFcwI0KJovjxBM_SwMd1RE6CymXirWbGRP3jfd9tgWkZoeAnZnV8FUcHmxXGlMlDjM_DQvNqjLbBfP0inV-cVc-8x32cfzfr8sRcChp3w67cEIRPvZxJvdPI5?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!27511&parId=BB74063D28EA3BAD!27509&authkey=!AOPAIKdhKQkrivc',
  },
  {
    title: 'Portrætter',
    subTitle: 'All about the brudepar',
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mvQ1tw2ijpFSWrm-GvGen61PMF4p4zo032R4XyK9OMPX4mhO3v7rRLelol4P_RBjYaLgD5Sli2jJ7LK4oLFLaW5cfXuZotX3_QtKgUgdZHyx4fpjDMqIq2zyLWMAS69n7PjA0wih47vfPsSKMiOhlxPsjPkQVuKII_JKr2X6f0MRQeqiJi5f-QKX2iBnhopdk?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!27514&parId=BB74063D28EA3BAD!27509&authkey=!AOae0nh6-4-BeIA',
  },
  {
    title: 'Fest',
    subTitle: 'Se tilbage på aftenen',
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mwBsUmWXCk9oan8VnYeN8jyQWERX3WbCZTipPAG6V1_kBq56A4vityBw8WJmKXZVrfNIK-7hMAhP4g4KSLTBnfjTiw9QmzS4xbSqbL0Muh7HmduLKa1bLV24kHkxadrL4USU9MSr688LXgmtGzrkvv4Svys90NMW_NvX5S8juCfNQ2KWY6fQgUMLCoGgmf29L?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!27512&parId=BB74063D28EA3BAD!27509&authkey=!AIjzHKjf0mSjCSs',
  },
  {
    title: 'Myselfie',
    subTitle: 'First let me take a selfie',
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mELYyOCFuQhlze5iNj_hTES4OeEUAmiPS-2rDP0cJ_R6Ybe6gLf_TvJ1SYRSPVdscKzzaU0PiSoRxIrhIeIvtnFbeVcZ1eFUqAZXfRtY4GPbE9py-E_dILUwjinf7KbMUUSPBmHQllcFU1q-sFiQOkayFD0CFYfnWREMCZy41gS2YWjteHeuhLqIMxptJU8ds?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!27510&parId=BB74063D28EA3BAD!27509&authkey=!APlQ-UB4gICCz2s',
  },
  {
    title: 'far.lysgaard-bakke.dk',
    subTitle: "Højdepunkter fra Ivan's billede arkiv",
    imgUrl:
      'https://dub01pap001files.storage.live.com/y4mvuQ7NmWlaEjzx-trkyR-8Y7EmibWSxINwQaFpbdq_4OZ3aqKna2PQHDjbbHQfi29Q8UiObxiP9FS3F24-81BiE_pybmhKiPyuAnt4esAVuZH-k4Zxla2avEnlVfwwh0N6Z7lVqL_-bc7Nk1jNoVedI_ThVN8CVNe1Se0Nsk9a3vDCGx9U8nphdHKPDCkA4eP?width=660&height=440&cropmode=center',
    href: 'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!28529&parId=BB74063D28EA3BAD!27509&authkey=!AGT9egx9ojWooAo',
  },
]

const Photos = () => {
  const theme = useTheme()

  return (
    <Box>
      <Typography variant="h2">Billeder</Typography>
      <Typography>
        Her kan du se billeder fra vores minder sammen og efter bryllupet kan de
        se de billeder som bliver taget i løbet af dagen af vores{' '}
        <Link href="https://www.kielstrupphotography.dk/" target="_blank">
          fotograf
        </Link>
        <Typography></Typography>
      </Typography>

      <Box m={8} />

      <ImageList>
        {photoContent.map((item) => (
          <ImageListItem
            component="a"
            key={item.imgUrl}
            href={item.href}
            target="_blank"
          >
            <img
              src={`${item.imgUrl}`}
              srcSet={`${item.imgUrl}`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              subtitle={item.subTitle}
              actionIcon={
                <IconButton
                  sx={{ color: theme.palette.primary.dark }}
                  aria-label={`info about ${item.title}`}
                >
                  <OpenInNewRoundedIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  )
}

export default Photos
