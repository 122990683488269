import { Box, BoxProps, Button, Stack, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useIsMobile } from '../utils/BreakPoint'
import { getS3FolderImages } from '../utils/S3utils'
import ImgCarousel from './ImgCarousel'
import { Outline } from './Outline'

export interface PrimaryItemProps {
  title?: string
  subTitle?: string
  text?: string
  actionText?: string
  orientation: 'left' | 'right'
  onClick?(): void
  imgFolderKey: string
}

interface PrimaryImgProps extends BoxProps {
  shift: 'left' | 'right'
  url?: string
}

const PrimaryImg = styled(Box)<PrimaryImgProps>`
  background-image: url('${(props) => props.url || ''}');
  background-color: #cccccc;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const PrimaryImgContainer = styled(Box)<PrimaryImgProps>`
  box-shadow: 0rem 1rem 5.125rem 0rem rgb(0 0 0 / 11%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: ${(props) => (props.shift === 'left' ? '5%' : 'none')};
  left: ${(props) => (props.shift === 'left' ? '5%' : 'none')};
  bottom: ${(props) => (props.shift === 'right' ? '5%' : 'none')};
  right: ${(props) => (props.shift === 'right' ? '5%' : 'none')};
`

const PrimaryImageBackground = styled(Box)<PrimaryImgProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: ${(props) => (props.shift === 'left' ? '-5%' : 'none')};
  left: ${(props) => (props.shift === 'left' ? '-5%' : 'none')};
  bottom: ${(props) => (props.shift === 'right' ? '-5%' : 'none')};
  right: ${(props) => (props.shift === 'right' ? '-5%' : 'none')};
  background-color: #a0d1c5;
`

const PrimaryText = styled(Box)`
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 49%;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: 100px;
    width: 100%;
  }
`

const PrimaryItem = ({
  title,
  subTitle,
  text,
  actionText,
  orientation,
  onClick,
  imgFolderKey,
}: PrimaryItemProps) => {
  const isMobile = useIsMobile()
  const [s3ImgUrls, setS3ImgUrls] = useState([''])

  useEffect(() => {
    const getData = async () => {
      const folderUrls = await getS3FolderImages(imgFolderKey)

      setS3ImgUrls(folderUrls)
    }

    getData()
  }, [imgFolderKey])

  const stackDirection = isMobile
    ? 'column'
    : orientation === 'left'
    ? 'row'
    : 'row-reverse'

  return (
    <Outline clickable={true} onClick={onClick} margin={2}>
      <Stack direction={stackDirection} spacing={2} width="100%">
        <Box
          position="relative"
          width={isMobile ? '100%' : '49%'}
          minHeight={isMobile ? '250px' : '350px'}
        >
          <PrimaryImageBackground shift={orientation} />

          <PrimaryImgContainer shift={orientation}>
            <ImgCarousel height="100%">
              {s3ImgUrls.map((imgUrl) => (
                <PrimaryImg url={imgUrl} shift={orientation}></PrimaryImg>
              ))}
            </ImgCarousel>
          </PrimaryImgContainer>
        </Box>

        <PrimaryText p={3}>
          {title && (
            <Typography color="primary" variant="h5">
              {title}
            </Typography>
          )}
          {subTitle && <Typography variant="caption">{subTitle}</Typography>}
          {text && (
            <Typography pt={2} variant="body1">
              {text}
            </Typography>
          )}
          {actionText && (
            <Box pt={2}>
              <Button>{actionText}</Button>{' '}
            </Box>
          )}
        </PrimaryText>
      </Stack>
    </Outline>
  )
}

export default PrimaryItem
