import { Box, Divider } from '@mui/material'

const PrettyDivider = () => {
  return (
    <Box py={3}>
      <Divider>
        <img height="40px" src="logo192.png" alt="test" />
      </Divider>
    </Box>
  )
}

export default PrettyDivider
