import { createTheme } from '@mui/material/styles'

import LucidaCalligraphy from '../fonts/Lucida Calligraphy Italic.ttf'

const lucidaCalligraphy = {
  fontFamily: 'Lucida Calligraphy Italic',
  fontStyle: 'italic',
  fontWeight: '600',
  src: `
   url(${LucidaCalligraphy}) 
 `,
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // eslint-disable-next-line no-use-before-define
        // body: theme.palette.mode === 'dark' ? darkScrollbar() : null,
      },
    },
  },
  typography: {
    fontFamily: '"Lucida Calligraphy Italic"',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lucidaCalligraphy],
      },
    },
  },
  palette: {
    type: 'light',
    text: {
      primary: '#3d2c24',
    },
    primary: {
      main: '#a0d1c5',
    },
    secondary: {
      main: '#4e342e',
    },
    background: {
      default: '#FFFFFF',
    },
  },
})

export default theme
