import { Box, Fade, Pagination, styled } from '@mui/material'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'

interface SliderProps {
  children?: ReactNode[]
  height?: string
}

const CarouselTransition = styled(Fade)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`

const PagnationContianer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomPagnation = styled(Pagination)(
  ({ theme }) => `
  & .MuiPaginationItem-sizeSmall {
    color: white;
    background-color: rgba(255, 250, 250, 0.25);
  }

  & .Mui-selected {
    color: ${theme.palette.primary.main}
    border: 1px solid rgba(27, 94, 32, 0.5);
    background-color: rgba(27, 94, 32, 0.12);
  }
`
)

const ImgCarousel: FunctionComponent<SliderProps> = ({ children, height }) => {
  const [showIndex, setShowIndex] = useState(0)
  const containerRef = useRef(null)

  const onClick = useCallback(() => {
    setShowIndex((showIndex + 1) % (children?.length || 0))
  }, [showIndex, setShowIndex, children])

  const handleChange = useCallback(
    (event, value) => {
      setShowIndex(value - 1)
    },
    [setShowIndex]
  )

  return (
    <Box
      position="relative"
      width="100%"
      height={height || '150px'}
      ref={containerRef}
    >
      {children?.map((item, index) => (
        <CarouselTransition
          key={index}
          in={index === showIndex}
          timeout={{
            enter: 700,
            exit: 100,
          }}
        >
          <Box onClick={onClick}>{item}</Box>
        </CarouselTransition>
      ))}

      {(children?.length || 0) > 1 && (
        <PagnationContianer>
          <CustomPagnation
            count={children?.length || 0}
            page={showIndex + 1}
            onChange={handleChange}
            color="primary"
            size="small"
            variant="outlined"
          />
        </PagnationContianer>
      )}
    </Box>
  )
}

export default ImgCarousel
