import { Button, Divider, Stack, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { ReactElement } from 'react'
import PrettyDivider from '../components/PrettyDivider'
import SendIcon from '@mui/icons-material/Send'
import PageHeader from '../layout/PageHeader'
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab'

const InvitationBodyText = styled((props) => (
  <Typography variant="body1" {...props} />
))`
  margin-bottom: 1rem;
`

const TimelineItemBox = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`

type ITimelineContent = {
  text: string
  time: string
  last?: boolean
  icon?: string
}

const overallTimelineItems: ITimelineContent[] = [
  {
    text: 'Vielse i Lyngby Kirke',
    time: '12:30',
  },
  {
    text: 'Fri leg',
    icon: 'enjoy.png',
    time: '13:30',
  },
  {
    text: 'Velkomst på Raadvad Kro',
    time: '15:00',
    icon: 'inn.png',
  },
  {
    text: 'Festen slutter',
    time: '03:30',
    icon: 'wave.png',
    last: true,
  },
]

const fyrrebakkenTimelineItems: ITimelineContent[] = [
  {
    text: 'Gaveåbning og pizza',
    time: '12:00',
    icon: 'gift-box.png',
  },
  {
    text: 'Hjem at sove',
    time: '15:00',
    icon: 'wave.png',
    last: true,
  },
]

const Invitation = (): ReactElement => {
  return (
    <>
      <Box>
        <PageHeader>
          <Typography variant="h2">Praktisk information</Typography>
        </PageHeader>
        <PrettyDivider />
        <InvitationBodyText>
          Det glæder os meget at kunne invitere til vores bryllup lørdag d. 12.
          februar 2022. <br></br> Alle corona restriltioner ophører fra d. 1.
          februar hvilket betyder, at vi kan få lov til at feste med jer ud på
          natten! <br></br> Her kan i se den overordnede tidsplan for dagen.
        </InvitationBodyText>

        <Timeline>
          {overallTimelineItems.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <TimelineItemBox sx={{ justifyContent: 'flex-end' }}>
                  {item.time}
                </TimelineItemBox>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <img
                    height="24px"
                    src={item.icon ? item.icon : 'logo192.png'}
                    alt="test"
                  />
                </TimelineDot>
                {!item.last && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <TimelineItemBox>{item.text}</TimelineItemBox>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        <InvitationBodyText>
          Lyngby Kirke, Lyngby Kirkestræde 1, 2800 Kongens Lyngby.{' '}
          <Box m={1} mb={4} display="flex" justifyContent="center">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Button
                href="/practical-church"
                target="_blank"
                variant="contained"
              >
                Parkering
              </Button>
              <Button href="/locations#lyngby-kirke" variant="contained">
                Mere om Lyngby Kirke
              </Button>
            </Stack>
          </Box>
        </InvitationBodyText>
        <InvitationBodyText>
          Raadvad Kro, Svenskevej 52, 2800 Kongens Lyngby.{' '}
          <Box m={1} mb={4} display="flex" justifyContent="center">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Button href="/practical-inn" target="_blank" variant="contained">
                Parkering
              </Button>
              <Button href="/locations#raadvad-kro" variant="contained">
                Mere om Raadvad
              </Button>
            </Stack>
          </Box>
        </InvitationBodyText>

        <PrettyDivider />

        <InvitationBodyText>
          Alle er velkomne til at slutte sig til os søndag d. 13. februar fra
          kl. 12 til ca. 15 på Fyrrebakken 10 i Birkerød. Her kan I få en bid
          pizza, en reparationsbajer og en sodavand mens vi alle deler historier
          fra dagen før og får et kig på de gaver der må være. Vi håber at se
          alle der har lyst og mulighed til denne lille ekstra ting, giv gerne
          en melding hvis du tænker, at kigge forbi.
        </InvitationBodyText>

        <Timeline>
          {fyrrebakkenTimelineItems.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <TimelineItemBox sx={{ justifyContent: 'flex-end' }}>
                  {item.time}
                </TimelineItemBox>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <img
                    height="24px"
                    src={item.icon ? item.icon : 'logo192.png'}
                    alt="test"
                  />
                </TimelineDot>
                {!item.last && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <TimelineItemBox>{item.text}</TimelineItemBox>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        <InvitationBodyText>
          Bakkerne i Bistrup, Fyrrebakken 10, 3460 Birkerød
        </InvitationBodyText>

        <PrettyDivider />
        <InvitationBodyText>
          Eventuelle indslag meldes i god tid til toastmasterene Stefán og Line
          Maj på indslag@lysgaard-bakke.dk
        </InvitationBodyText>
        <InvitationBodyText>
          Ønskeliste kan rekvireres hos vores gavekoordinator Helle på
          gaver@lysgaard-bakke.dk
        </InvitationBodyText>
        <InvitationBodyText>
          Dresscoden ønsker vi festlig og med kridtede dansesko.
        </InvitationBodyText>

        {/* <PrettyDivider />
        <InvitationBodyText>
          Svar udbedes senest 12. december 2021 på svar@lysgaard-bakke.dk
        </InvitationBodyText>
        <InvitationBodyText>
          Angiv venligst eventuelle særlige diæter{' '}
        </InvitationBodyText>
        <InvitationBodyText>
          (fx allergi, vegetar, saftmenu/alkoholfri).
        </InvitationBodyText> */}

        {/* <InvitationBodyText>Kærlig hilsen</InvitationBodyText>
        <InvitationBodyText>Pernille & Fredrik</InvitationBodyText> */}
        <Box m={4} display="flex" justifyContent="center">
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <Button
              href="mailto:indslag@lysgaard-bakke.dk"
              variant="contained"
              endIcon={<SendIcon />}
            >
              Indslag
            </Button>
            <Button
              href="mailto:gaver@lysgaard-bakke.dk"
              variant="contained"
              endIcon={<SendIcon />}
            >
              Gaver
            </Button>
            {/* <Button
              href="mailto:svar@lysgaard-bakke.dk"
              variant="contained"
              endIcon={<SendIcon />}
            >
              Svar
            </Button> */}
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default Invitation
