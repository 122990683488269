import { Storage } from 'aws-amplify'

export const getImgS3Url = async (key: string) => {
  const signedURL = await Storage.get(key)
  return signedURL
}

export const getMultipleS3Urls = async (keys: string[]) => {
  const s3Urls = await Promise.all(
    keys.map(async (key) => {
      return await getImgS3Url(key)
    })
  )

  return s3Urls
}

export const getS3FolderImages = async (folderKey: string) => {
  const folderContents = await Storage.list(folderKey)

  const folderKeys = folderContents
    .filter((item) => (item.size || 0) > 0)
    .map((item) => item.key || '')

  return getMultipleS3Urls(folderKeys)
}
