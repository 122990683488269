import { Box, BoxProps, styled } from '@mui/system'

interface OutlineProps extends BoxProps {
  clickable?: boolean
}

export const Outline = styled(Box)<OutlineProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  box-shadow: 0rem 1rem 5.125rem 0rem rgb(0 0 0 / 11%);
  background-color: #ffffff;
`
