import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { ReactElement, ReactNode, useEffect } from 'react'
import Header from './Header'
import { styled } from '@mui/material/styles'
import Footer from './Footer'
import NewsLetter from '../components/NewsLetter'

const RelativeContainer = styled(Container)`
  position: relative;
  padding-bottom: 120px;
  min-height: 88vh;

  padding: ${(props) => props.theme.spacing(4)};
  padding-bottom: 120px;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(2)};
    padding-top: ${(props) => props.theme.spacing(4)};
    padding-bottom: 80px;
  }
`

export interface PageProps {
  children: ReactNode
  extraMargin?: boolean
}

const Page = ({ children }: PageProps): ReactElement => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <RelativeContainer fixed>
        <Box textAlign="center">{children}</Box>

        <NewsLetter />
      </RelativeContainer>
      <Footer />
    </>
  )
}

export default Page
