import { Stack, styled, Drawer, IconButton, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useIsMobile } from '../utils/BreakPoint'
import { NavigationItems } from '../utils/Navigation'
import MenuIcon from '@mui/icons-material/Menu'
import { Placement } from '../utils/ZIndex'
import { OurButton } from '../components/OurButton'

const CornerBoxTop = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: ${Placement.back};

  width: 40vw;

  ${theme.breakpoints.down('sm')} {
    width: 70vw;
  }
`
)

const Header = () => {
  const history = useHistory()
  const [showMenu, setShowMenu] = useState(false)

  const isMobile = useIsMobile('md')

  const toggleShowMenu = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu, setShowMenu])

  return (
    <>
      <Box
        display="flex"
        position="relative"
        justifyContent={isMobile ? 'flex-end' : 'center'}
        alignItems="center"
        height="10vh"
      >
        <CornerBoxTop>
          <img src="top_corner.png" alt="Corner" width="100%" height="100%" />
        </CornerBoxTop>

        {!isMobile && (
          <Grid container m={2} justifyContent="flex-end">
            <Grid
              width="60vw"
              item
              container
              justifyContent="space-evenly"
              spacing={2}
            >
              {NavigationItems.filter((nav) => !nav.disabled).map((navItem) => (
                <Grid item key={navItem.name}>
                  <OurButton onClick={() => history.push(navItem.url)}>
                    {navItem.name}
                  </OurButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleShowMenu}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        )}

        <Drawer anchor="left" open={showMenu} onClose={toggleShowMenu}>
          <Stack direction="column" spacing={1} m={4}>
            {NavigationItems.filter((nav) => !nav.disabled).map((navItem) => (
              <OurButton
                key={navItem.name}
                onClick={() => history.push(navItem.url)}
              >
                {navItem.name}
              </OurButton>
            ))}
          </Stack>
        </Drawer>
      </Box>
    </>
  )
}

export default Header
