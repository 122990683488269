import React from 'react'
import { Box, styled } from '@mui/material'
import { Placement } from '../utils/ZIndex'

const CornerBoxBottom = styled(Box)(
  ({ theme }) => `
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: ${Placement.back};

  width: 40vw;

  ${theme.breakpoints.down('sm')} {
    width: 70vw;
  }
`
)

const Footer = () => {
  return (
    <Box position="relative">
      <CornerBoxBottom>
        <img src="bottom_corner.png" alt="Corner" width="100%" height="100%" />
      </CornerBoxBottom>
    </Box>
  )
}

export default Footer
