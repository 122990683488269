import { Snackbar, SnackbarContent, Button, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import SendIcon from '@mui/icons-material/Send'
import { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const NewLetterSub = styled(Snackbar)`
  background-color: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};

  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.background.default};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: -18px;
  left: -18px;
`

const NewsLetter = () => {
  const [showNewsLetter, setShowNewsLetter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowNewsLetter(true)
    }, 3000)
  }, [])

  const closeNewsLetter = useCallback(() => {
    setShowNewsLetter(false)
  }, [setShowNewsLetter])

  return (
    <>
      <NewLetterSub
        open={showNewsLetter}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        color="#FFFFFF"
      >
        <SnackbarContent
          message="Vi har stadig mere at fortælle. Vil du vide når vi opdaterer siden?"
          action={
            <>
              <Button
                href="https://eepurl.com/hMeXH1"
                target="_blank"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={closeNewsLetter}
              >
                ja tak!
              </Button>
              <CloseButton
                onClick={closeNewsLetter}
                color="primary"
                aria-label="Close news letter"
              >
                <CloseIcon />
              </CloseButton>
            </>
          }
        ></SnackbarContent>
      </NewLetterSub>
    </>
  )
}

export default NewsLetter
