import { Box } from '@mui/material'
import React from 'react'

type PdfProps = {
  file: string
}

const Pdf = ({ file }: PdfProps) => {
  return (
    <Box>
      <img alt={file} src={file} width="100%" />
    </Box>
  )
}

export default Pdf
