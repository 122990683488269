import { Grid, Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { useIsMobile } from '../utils/BreakPoint'

interface PageHeaderProps {
  children: ReactNode
}

const PageHeader = ({ children }: PageHeaderProps) => {
  const isMobile = useIsMobile()
  return (
    <>
      <Grid container justifyContent={isMobile ? 'flex-end' : 'center'}>
        <Box mb={2} width={isMobile ? '85%' : '100%'}>
          {children}
        </Box>
      </Grid>
    </>
  )
}

export default PageHeader
