import { Box, Grid, Typography, styled } from '@mui/material'
import { useHistory } from 'react-router'
import PrimaryItem from '../components/PrimaryItem'
import { useIsMobile } from '../utils/BreakPoint'

const HeroImage = styled(Box)`
  background-image: url('hero_img.jpg');
  background-color: #cccccc;
  height: 1000px;
  width: 80%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 40% 5% 5% 5%;
  ${(props) => props.theme.breakpoints.down('md')} {
    height: 325px;
    width: 90%;
  }
`

// const Overlay = styled(Box)`
//   display: block;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background: radial-gradient(transparent 23%, white 78%);
//   opacity: 0.8;
//   top: 0;
//   border-radius: 40% 5% 5% 5%;
// `

const HeroText = styled(Box)`
  text-align: center;
  position: absolute;
  width: 100%;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
`

interface WelcomeItem {
  title: string
  text: string
  actionText: string
  url: string
  disabled: boolean
  imgKey?: string
}

const contentItems: WelcomeItem[] = [
  {
    title: 'Tidslinje',
    text: 'Fed tidslinje',
    actionText: 'Se tidslinje',
    url: '/timeline',
    disabled: true,
  },
  {
    title: 'Persongalleri',
    text: 'Hvem er det nu, der er brudeparrets forældre når man gerne vil sige “Tillykke med dem” efter kirken? Hvem er ham der forloveren egentlig? Og hvorfor er det som om, der er en der hele tiden er optaget af, om den hvide kjole sidder rigtigt? Vi har hørt dig og lavet et persongalleri! Kom og mød nogle af de ansigter der er gode at kunne genkende på dagen.',
    actionText: 'Tryk for at komme til persongalleriet',
    url: '/roles',
    disabled: false,
    imgKey: 'homepage/wedding_crew.png',
  },
  {
    title: 'Lokationer',
    text: 'Vi føler virkelig at vi har fundet os et hjem i Kongens Lyngby, “vores” Lyngby. Derfor glæder mig os helt enormt meget over de steder vi har fundet til at danne rammerne om vores bryllup. Herinde kan du både få lidt lokalhistorie og en introduktion til lokationerne.',
    actionText: 'Tryk for at læse om lokationerne',
    url: '/locations',
    disabled: false,
    imgKey: 'homepage/lyngby_1950.jpg',
  },
  {
    title: 'Billeder',
    text: 'Gå på opdagelse i billeder og se bryllups billeder når de kommer',
    actionText: 'Gå på opdagelse',
    url: '/photos',
    disabled: true,
  },
  {
    title: 'Det praktiske',
    text: 'Hvis du gerne vil svare på invitationen, have en ønskeliste, melde et indslag, finde adressen på kroen og huske hvornår vielsen starter i kirken, så er det her du skal gå hen. Vi har samlet den praktiske information for dig og lavet smarte genveje til at komme i kontakt med os.',
    actionText: 'Tryk for at komme til det praktiske',
    url: '/practical',
    disabled: false,
    imgKey: 'homepage/20211029_131043.jpg',
  },
]

const Welcome = () => {
  const history = useHistory()
  const isMobile = useIsMobile()
  return (
    <>
      <Grid container justifyContent={isMobile ? 'flex-end' : 'center'}>
        <HeroImage>
          <HeroText>
            <Typography variant={isMobile ? 'h4' : 'h1'}>
              Vi skal giftes
              <br></br>
              og du skal med!
            </Typography>
          </HeroText>
        </HeroImage>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box my={isMobile ? 2 : 10}>
            {/* <Typography variant={isMobile ? 'h5' : 'h1'}>
              Vi skal giftes
              <br></br>
              og du skal med!
            </Typography> */}

            <Typography mt={4} variant={isMobile ? 'h6' : 'h4'}>
              Velkommen til, hvor er det dejligt du kigger forbi. Vi håber du
              vil gå på opdagelse på vores side og få lidt stemning og lidt
              information.
            </Typography>
          </Box>
        </Grid>

        {contentItems
          .filter((item) => !item.disabled)
          .map((item, index) => (
            <Grid key={item.title} item xs={12}>
              <PrimaryItem
                title={item.title}
                text={item.text}
                actionText={item.actionText}
                orientation={index % 2 === 0 ? 'left' : 'right'}
                onClick={() => history.push(item.url)}
                imgFolderKey={item.imgKey || 'raadvad.jpg'}
              />
            </Grid>
          ))}

        {/* {contentItems
          .filter((item) => !('orientation' in item))
          .map((item) => (
            <Grid item xs={12} md={4}>
              <SecondaryItem
                title={item.title}
                text={item.text}
                actionText={item.actionText}
                onClick={() => history.push(item.url)}
                imgKeys={['ferrarif12.jpg']}
              />
            </Grid>
          ))} */}
      </Grid>
    </>
  )
}

export default Welcome
