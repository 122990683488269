import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import PrettyDivider from '../components/PrettyDivider'
import PrimaryItem from '../components/PrimaryItem'
import PageHeader from '../layout/PageHeader'
import { useIsMobile } from '../utils/BreakPoint'

interface Person {
  name: string
  desc: string
  role: string
  imgKey: string
}

const mainPeople: Person[] = [
  {
    name: 'Lucas',
    role: 'Forlover',
    imgKey: 'persons/lucas/',
    desc: 'Næsten den eneste tilstedeværende ved brylluppet, der ikke er i familie med brudeparret, men har kendt Fredrik længere tid end Pernille har. De to har været lidt af hvert igennem over årene, meget af det båret på en fælles passion for basket og solide indsatser for at tage Birkerød Basket helt til Danmarksmesternamen for U21 i 2011. De deler også mange andre interesser og vigtigst af alt, så griner, smiler og trives de altid i hinandens selskab.',
  },
  {
    name: 'Christina',
    role: 'Brudepige',
    imgKey: 'persons/christina/',
    desc: 'Christina er brudens højre hånd på dagen, og mange andre dage, nu vi er ved det. Christina og Pernille mødte hinanden i forbindelse med deres udveksling til Sydney i Australien og lagde godt ud med et par drinks og en middag på en uventet buisness class billet. Men der udover var første indskydelse, at de var meget forskellige og måske ikke skulle bruge så meget tid sammen. Intet kunne have været fjernere fra sandheden. Nok er de to forskellige, men de er også meget ens. De har en dynamik som er bemærkelsesværdig og et bånd der er tættere end de flestes. De elsker at tage på store og små eventyr, sammen er der ikke det de ikke kan. Christina er desuden nok den første udenfor familien til at tage kaldenavnet ”P” til sig for Pernille.',
  },
  {
    name: 'Line Maj',
    role: 'Toastmasterduoen del 1',
    imgKey: 'persons/topsi/',
    desc: 'Bedre kendt fra gymnasiet som Topsi, hvor hun udgør én af de ni piger der er Apropos Æggestokke gruppen, berømt og berygtede for deres topløse med-ryggen-til-kameraet billeder fra den årlige sommerhustur. Foruden en vigtig del af gruppedynamikken er hun en kær ven af bruden. De to har lavet små og store projekter gennem tiderne, nogle med fagligt indhold og andre helt uden. De deler en fortrolighed og en oprigtighed som er dybt værdsat. Desuden er hun lidt af en eventyrer og altid god for en fantastisk historie, et godt grin og en livlig stemning.',
  },
  {
    name: 'Stefán',
    role: 'Toastmasterduoen del 2',
    imgKey: 'persons/stefan/',
    desc: 'Anden halvdel af toastmasterduoen. Stefán og Fredrik har delt surt og sødt hele studietiden igennem. Afsluttede med den maksimale prøvelse under specialeskrivningen og kom igennem med hinanden og et udfordrende minde rigere. Stefán er en del af Den Uendelige Hobs Hårde Kerne, det vil sige de af Fredriks studiekammerater der virkelig hænger sammen og gør det ud for en samlet helhed. Sammen med Hoben (nogle gange også kendt som Slænget) bliver der holdt juefrokoster, sommerhusture, middage, nytår og alt der imellem.',
  },
]

const familyMembers: Person[] = [
  {
    name: 'Martin',
    role: 'Brudens bror',
    imgKey: 'persons/martin/',
    desc: 'Pernilles første og ældste ven, han har i sandhed været der siden den første dag. Til trods for drillerier, skænderier, gummihamre og andre kontroverser, så står de to hinanden bi i tykt og tyndt. Aldrig skal man være i tvivl om, at den ene har den andens ryg og vice versa. Forskellighederne er mange, men lighederne er ligeledes. Ofte kan man få forklaringen fra Pernille at ”Martin er alt det jeg er, bare mere”. Martin er desuden måske brudeparrets største fan og har alle dage budt Fredrik hjerteligt velkommen som sin kommende svoger.',
  },
  // {
  //   name: 'Jonatan',
  //   role: 'Gommens ældste lillebror',
  //   desc: 'Jonatan er med sine knap 10 cm mere højde end Fredrik svær helt at kalde lillebror, men på kalenderen er han det alligevel. De to har delt livet sammen og særligt under deres barndom i USA kan man mærke, at de blev sammentømret til et rigtigt makkerpar. De to deler i dag en troværdighed og tillid der kan mærkes og en interesse for software.',
  // },
  // {
  //   name: 'Nicolai',
  //   role: 'Gommens yngste lillebror',
  //   desc: 'Igen har Fredrik trukket det korteste strå på målepinden, men Nicolai har dog ladet nogle flere år gå, før det kom så vidt. Han er en ægte lillebror der nyder godt af sine store brødres kærlighed og banen af vejen for fester i huset og andre goder. Fredrik og Nicolai har brugt utallige timer sammen på en basketbane, både den i forældrenes have og den i Søndervangshallen. Deres kærlighed til den orange bold er stor og gensidig. Desuden har de for nyligt fundet en fælles interesse i biler.',
  // },
  {
    name: 'Jonatan & Nicolai',
    role: 'Gommens brødre',
    imgKey: 'persons/jonatan_nicolai/',
    desc: 'Trip-trap-træsko, mellemst-yngst-ældst. Sådan går det nogle gange med søskende, at selvom man er storebror, så får man ikke lov at forblive den højeste. Dette har på ingen måde været nogen hindring for disse tre i at finde harmonien. De tre har en kærlig, tolerant og venlig måde at omgås hinanden på. De støtter hinanden og de deler gerne alle slags oplevelser og har en stor familie ånd. Som trio har de både deres egne interesser og interesser de deler på kryds og tværs, hvilket giver dem en utrolig velfungerende dynamik.',
  },
  {
    name: 'Ivan',
    role: 'Brudens far',
    imgKey: 'persons/ivan/',
    desc: 'Altid god for en krammer, et smil og en solid brainstorm session. Der er ikke mange projekter i hverken Ivans eller Pernilles liv, der ikke lige bliver diskuteret over mindst én middag. Derudover, deler de en masse interesser indenfor musik, måske mest fordi Ivan har sørget for at opdrage Pernille i musik fra 80’erne og 90’erne som var det der Pernille selv var ung. Melodi Grand Prix er også noget Pernille og Ivan deler, så vi kan måske takke brudens far for, at brudeparret endelig fik den alenetid tilbage i 2011. Mellem Fredrik og Ivan blomstrer idéerne også lystigt, alt fra programmering, office-løsninger, hjemmeautomatisering og andet teknik kan de to snakke om i timevis. ',
  },
  {
    name: 'Helle',
    role: 'Brudens mor',
    imgKey: 'persons/helle/',
    desc: 'Bedst i test, alle former for test. Fra Pernille var helt lille har mors værktøjskasse kunne først det hele, siden langt det meste. Hvad end der skulle lappes cykel, holdes hest eller vejledes i livets mange udfordringer. De to er meget tætte og deler hverdagens små og store oplevelser over en kop the eller på telefonen. Hun har også været helt tidlig i sin udmelding om, at Fredrik han er mere end godkendt både på personlighed og gener. Mor-helle er desuden vældig god til at komme med løsninger og være praktisk når noget brænder lidt på, hun har derfor også fået til opgave at holde det praktiske overblik over brudeparrets ønskeliste og gavekoordinering. ',
  },
  {
    name: 'Erik & Dorte',
    role: 'Gommens forældre',
    imgKey: 'persons/erik_dorte/',
    desc: 'Hvor der er hjerterum er der husrum. Det har der i sandhed altid været på Fyrrebakken hos Dorte og Erik. I højeste grad da Pernille begyndte at komme i Bakke-hjemmet i Bistrup, men tydeligvis også i drengenes opvækst. Det er her man mødes til computerspil, havebadminton, basketball, vandkamp og så meget mere. De har altid bakket op om de interesser, studier og retninger man har ønsket at gå i. Desuden har de altid sat et godt eksempel for hvordan man kan overraske og passe på sine elskede med surprise limousiner og Paristurer.',
  },
  // {
  //   name: 'Irma, Elisabeth, Finn & Hanne',
  //   role: 'Brudens bedsteforældre',
  //   desc: 'Til stede på dagen bliver farmor Irma, mormor Elisabeth, morfar Finn og Hanne (”min morfars kone, som ikke er min mormor” citat lille P). Med os i tankerne er farfar Immanuel og Palle (”min mormors mand” citat lille P). En dejlig stor flok af bedsteforældre på 4, engang 6, der har holdt af, holdt om og holdt ferie med bruden siden hun var helt lille. Hver og en har de bidraget med hver deres, især den del af opdragelsen der har omhandlet landlighed, kultur, kunst og udflugter i det københavnske. ',
  // },
]
const Roles = () => {
  const isMobile = useIsMobile()
  return (
    <>
      <PageHeader>
        <Typography variant="h2">
          {isMobile ? (
            <>
              Person- <br></br> galleri
            </>
          ) : (
            'Persongalleri'
          )}{' '}
        </Typography>
      </PageHeader>
      <Box my={2} />
      <Typography variant="body1">
        Der er mange ting at holde styr på til sådan et bryllup. Der er
        planlægning, nerver, mange bolde i luften, løse ender, problemer og
        succeser. Alle sammen er de bedre, hvis man deler dem med nogen og
        udover, at brudeparret deler det med hinanden, så kommer der her en
        lille introduktion af nogle af de mennesker der har været ekstra meget
        inde over hele processen. De ved derfor også rigtig meget om det hele,
        og kan måske være til hjælp hvis der skulle være noget, I ikke synes
        skal direkte til brudeparret, enten på forhånd eller på dagen.
      </Typography>

      <Grid my={2} container spacing={2}>
        {mainPeople.map((person, index) => (
          <Grid key={person.name} xs={12} item>
            <PrimaryItem
              title={person.name}
              subTitle={person.role}
              text={person.desc}
              orientation={index % 2 === 0 ? 'left' : 'right'}
              imgFolderKey={person.imgKey}
            />
          </Grid>
        ))}
      </Grid>
      <PrettyDivider />

      <Typography variant="h3">Familien</Typography>
      <Box py={2}>
        <Typography variant="body1">
          Derudover består sådan et bryllup for de fleste af en masse fremmede,
          men sommetider er det rart lige at have styr på nogle nøgle
          relationer. Derfor følger der her en lille præsentation af
          brudeparrets nærmeste familie.
        </Typography>
      </Box>

      <Grid my={2} container spacing={2}>
        {familyMembers.map((person, index) => (
          <Grid key={person.name} xs={12} item>
            <PrimaryItem
              title={person.name}
              subTitle={person.role}
              text={person.desc}
              orientation={index % 2 === 0 ? 'left' : 'right'}
              imgFolderKey={person.imgKey}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Roles
