import React, { ReactNode } from 'react'
import Invitation from '../pages/Invitation'
import Locations from '../pages/Locations'
import Photos from '../pages/Photos'
import Roles from '../pages/Roles'
import TimelinePage from '../pages/Timeline'
import Welcome from '../pages/Welcome'

interface NavigationItem {
  name: string
  url: string
  component: ReactNode
  disabled: boolean
}

export const NavigationItems: NavigationItem[] = [
  {
    name: 'Forside',
    url: '/',
    component: <Welcome />,
    disabled: false,
  },
  {
    name: 'Lokationer',
    url: '/locations',
    component: <Locations />,
    disabled: false,
  },
  {
    name: 'Billeder',
    url: '/photos',
    component: <Photos />,
    disabled: false,
  },
  {
    name: 'Persongalleri',
    url: '/roles',
    component: <Roles />,
    disabled: false,
  },
  {
    name: 'Vores historie',
    url: '/timeline',
    component: <TimelinePage />,
    disabled: true,
  },
  {
    name: 'Det Praktiske',
    url: '/practical',
    component: <Invitation />,
    disabled: false,
  },
]
