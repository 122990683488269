import { BoxProps, Typography, TypographyProps } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import ImgCarousel from '../components/ImgCarousel'
import PrettyDivider from '../components/PrettyDivider'
import PageHeader from '../layout/PageHeader'
import { useIsMobile } from '../utils/BreakPoint'
import { getMultipleS3Urls } from '../utils/S3utils'

const BodyText = styled((props) => (
  <Typography textAlign="justify" variant="body1" {...props} />
))`
  margin-bottom: 16px;
`

const Test = (props: TypographyProps) => (
  <Typography color="primary" variant="h5" {...props} />
)

const LocationTitle = styled(Test)`
  margin-top: 32px;
`

const SubTitle = styled((props) => <Typography variant="caption" {...props} />)`
  margin-bottom: 16px;
`

interface LocationImgProps extends BoxProps {
  imgUrl: string
}

const LocationImg = styled(Box)<LocationImgProps>`
  background-image: url('${(props) => props.imgUrl}');
  background-color: #cccccc;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`

const Locations = () => {
  const [chuchUrls, setChurchUrls] = useState<string[]>([])
  const [kroUrls, setKroUrls] = useState<string[]>([])

  const isMobile = useIsMobile()

  useEffect(() => {
    const getData = async () => {
      const churchS3Urls = await getMultipleS3Urls([
        'locations/lyngby_kirke.jpg',
        'locations/Lyngbykirke_2.jpg',
      ])
      const kroS3Urls = await getMultipleS3Urls([
        'locations/raadvad.jpg',
        'locations/Raadvadkro_2.jpg',
      ])

      setChurchUrls(churchS3Urls)
      setKroUrls(kroS3Urls)
    }

    getData()
  }, [])

  return (
    <>
      <PageHeader>
        <Typography mb={2} variant="h2">
          Lokationer
        </Typography>
      </PageHeader>

      <BodyText>
        Vigtigst af alt for vores valg af lokationer er det, at vi føler os
        hjemme i Kongens Lyngby. Vi elsker vores nye lille hjem i lejligheden på
        Lyngbygårdsvej. Vi nyder bylivet på hovedgaden med detailhandel og
        caféer. Vi værdsætter alle de grønne områder så utroligt meget. Alt fra
        morgengåture i Sorgenfri Slotspark til større ture til Dyrehaven, Lyngby
        sø eller langs Mølleåen er med til at gøre vores dage fyldt med frisk
        luft og grønne indtryk, hvilket bringer meget glæde og trivsel for os
        begge to.
      </BodyText>
      <BodyText>
        Fredrik valgte at fri til Pernille på den første dag vi officielt boede
        i Lyngby, som også var vores 10 års kæreste dag! Til lyden af Lyngby
        Kirkes klokker i distancen, sikke en timing. Et rigtig
        nyt-kapitel-frieri, med rødder i Lyngby.
      </BodyText>
      <BodyText>
        Derfor føles det helt rigtige at holde brylluppet indenfor det vi kalder
        “Lyngby Lokal” og deraf med vielse i Lyngby Kirke og efterfølgende
        fejring på Raadvad Kro.
      </BodyText>
      <BodyText>
        Disse to særlige steder vil vi gerne præsentere for jer herunder.
      </BodyText>

      <PrettyDivider />

      <LocationTitle id="lyngby-kirke">Lyngby Kirke</LocationTitle>
      <SubTitle>Lyngby Kirkestræde 1, 2800 Kongens Lyngby</SubTitle>
      <Box mb={2} />

      <ImgCarousel height={isMobile ? '250px' : '500px'}>
        {chuchUrls.map((url) => (
          <LocationImg key={url} imgUrl={url} />
        ))}
      </ImgCarousel>
      <Box mb={2} />
      <BodyText>
        Lyngby Kirke er opført i granit sten i midten af 1100-tallet i romansk
        stil og er dermed en gammel smuk middelalder kirke, der desuden menes at
        være Lyngbys ældste eksisterende bygning. Kirken er smukt beliggende på
        en bakke der løfter den over den omkringliggende bebyggelse. For os
        personligt, har det altid været symbolet på, at man ankommer til Lyngby.
        Især, da vi begge er vokset op med at ankomme til Lyngby nord fra og
        kirkebakken nærmest markere begyndelsen af Lyngby Hovedgaden og dermed
        ofte destinationen for handelsbesøg. Bakken giver også anledning til en
        meget karakteristisk udseende kirkegård med anlagte terrasser i bakken
        til gravsteder og den er dekoreret med et antal store træer. Desuden kan
        vi anbefale at man ankommer til kirken ad den brolagte allé på sydsiden
        af kirken, da det er en meget smuk indgang. Ellers er der et antal
        trapper rundt om kirken som gør adgangen lidt lettere.
      </BodyText>
      <BodyText>
        Kirkerummet er ganske særligt og smukt dekoreret med nogle meget
        karakteristiske kalkmalerier i loftet. Malerierne menes at være malet i
        1500-tallet i forbindelse med at hvælvingsfagene blev indbygget i skibet
        og koret. Nationalmuseet vurderer at malerierne er udført af
        Brarupværkstedet og at farverne har været mere varierede end i dag hvor
        primært den rødbrune farve står tilbage. Motiverne er ikke kun
        dekorative, men tager udgangspunkt i middelaldermenneskets hverdag og
        søger at understrege præstens ord.
      </BodyText>
      <BodyText>
        Kirken har tre sognepræster, hende der skal vie os er Cecilia Lotko
        Pontoppidan. Cecilia har blandt andet nogle Godmorgengudstjenester i
        kirken, hvor der er tid til fordybelse, ro og at nyde musik fra kirkens
        dygtige organister, pianister og kirkesangere. Vi oplever hende som
        værende nærværende, moderne og kærlig i sin prædiken og glæder os til at
        hun skal afholde vielsen.
      </BodyText>

      <PrettyDivider />

      <LocationTitle id="raadvad-kro">Raadvad Kro</LocationTitle>
      <SubTitle>Svenskevej 52, 2800 Kongens Lyngby</SubTitle>
      <Box mb={2} />
      <ImgCarousel height={isMobile ? '250px' : '500px'}>
        {kroUrls.map((url) => (
          <LocationImg key={url} imgUrl={url} />
        ))}
      </ImgCarousel>
      <Box mb={2} />
      <BodyText>
        Raadvad Kro ligger i den lille industriby der voksede op omkring
        Raadvaddams Fabrikker i sidste halvdel af 1700-tallet og starten af
        1800-tallet. Fabrikkerne var blevet anlagt efter kong Frederik V
        skænkede sin kammertjener Nicolai Jacob Jessen et gavebrev på Raadvad
        dam i 1758. Efter første verdenskrig skiftede fabrikkerne navn til
        Raadvad Knivfabrik, fabriksbygningerne bliver i dag mest identificeret
        med tiden som knivfabrik. Raadvad var et lille samfund hvor alle der var
        ansat på fabrikken boede i byen og det der i dag er vandrehjem, var
        skole for børnene.
      </BodyText>
      <BodyText>
        I 1861 stod bygningerne der i dag er Raadvad Kro færdige som et nyt
        hotel der skulle styrke byens økonomi. Hotellet tilbød mad og drikke,
        sejlture på dammen og det fortælles, at det i mange år var en fast
        tradition for nybagte studenter at ende deres skovtur netop her. I
        jagtsæsonen hændte det, at Raadvad havde kongeligt og kejserligt besøg,
        hvor der blev indtaget jagtfrokost. Åbningen af kystbanen i slutningen
        af 1800-tallet var hård for hotellet og i 1916 lukkede hotellet og
        bygningerne blev i stedet brugt som direktør bygning for Raadvad
        Knivfabrik. I 1930 besluttede knivfabrikkens bestyrelse dog, at stedet
        atter skulle åbnes og bruges til servering. Dermed har det været Raadvad
        Kro lige siden.
      </BodyText>
      <BodyText>
        Raadvad er for os, en fantastisk lille perle gemt væk i Dyrehavens dybe
        store skove. Her er der ro fra omverdenen og den fredede lille landsby
        er fyldt med stemning og historie. Vi bruger området som indgang til
        lange gåture i Dyrehaven eller en tur om dammen og nyder meget at komme
        der. At gå ind i Dyrehaven fra Raadvad giver nem adgang til nogle af
        Dyrehavens lidt fjernere områder hvilket betyder, at vi næsten hver gang
        ser dådyr og kronhjorte på helt tæt hold. Med en lidt længere tur kommer
        man også først forbi den fantastiske udsigt fra Eremitageslottet og
        derfra nemt til de meget smukke, fascinerende og vildt gamle egetræer i
        havens øst-sydøstlige del.
      </BodyText>
      <BodyText>
        Kroen finder vi enormt charmerende og den har en intimitet og
        originalitet som vi virkelig glæder os til at bruge som rammerne for
        vores bryllup. Desuden har vores besøg på kroen som restaurantgæster
        såvel som potentielle selskabsværter været præget af lækker mad og
        service på meget højt niveau.
      </BodyText>
      <BodyText>
        Hertil kommer, at turen ud til Raadvad går ad små snoede veje langs med
        Mølleåen og mellem store træer ind i kilen mellem Dyrehaven og
        Jægersborghegn. Det er en køretur der for Fredrik nærmest kun overgås af
        hårnålesving på store bjergsider. Ikke dårligt for noget der befinder
        sig lige i vores baghave her i pandekage-flade Danmark.
      </BodyText>
      <BodyText>
        Vi håber I kommer til at synes lige så godt om kroen som vi gør, at I
        vil nyde køreturen derud og at der er lyst længe nok til, at I kan få et
        glimt af den smukke natur der omgiver Raadvad.
      </BodyText>

      <Box my={10} />
    </>
  )
}

export default Locations
