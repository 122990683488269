import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './App.css'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import theme from './theme/theme'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Page from './layout/Page'
import { NavigationItems } from './utils/Navigation'

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import Pdf from './pages/Pdf'
import { useEffect } from 'react'
Amplify.configure(awsconfig)

function App() {
  useEffect(() => {
    if (window.location.host.includes('far.lysgaard-bakke')) {
      window.location.replace(
        'https://onedrive.live.com/redir.aspx?cid=bb74063d28ea3bad&resid=BB74063D28EA3BAD!28529&parId=BB74063D28EA3BAD!27509&authkey=!AGT9egx9ojWooAo'
      )
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            {NavigationItems.filter((nav) => !nav.disabled).map((nav) => (
              <Route exact key={nav.name} path={nav.url}>
                <Page>{nav.component}</Page>
              </Route>
            ))}

            <Route exact path="/practical-church">
              <Pdf file="/Parkering_LyngbyKirke.jpg" />
            </Route>
            <Route exact path="/practical-inn">
              <Pdf file="/Parkering_RaadvadKro.jpg" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
