import {
  Typography,
  Box,
  styled,
  Stack,
  Divider,
  BoxProps,
} from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Outline } from '../components/Outline'
import ImgCarousel from '../components/ImgCarousel'
import { useIsMobile } from '../utils/BreakPoint'
import { useEffect, useState } from 'react'
import { getImgS3Url } from '../utils/S3utils'

interface TimelinePageItem {
  title: string
  text?: string
  date?: string
  imgUrl?: string
}

interface TimelineImgProps extends BoxProps {
  imgUrl: string
}

const TimelineImg = styled(Box)<TimelineImgProps>`
  background-image: url('${(props) => props.imgUrl}');
  background-color: #cccccc;
  height: 250px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 150px;
  }
`

const timelineItems: TimelinePageItem[] = [
  {
    title: 'Første skoledag, 1.t Birkerød Gymnasium',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Kaffeballet 1.t',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Grand Prix',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Øvrig gymnasietid',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Krakow',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Studenter Birkerød Gymnasium',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Lübeck, første ferie sammen',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Studiestart for P arbejdsliv for F',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Studiestart for F studieliv for P',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Gran Canaria, flere ferier',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Australien, hver for sig og sammen',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Flytte hjemmefra',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Flytte sammen',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Indonesien',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Færdiguddannet P',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Felix',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Færdiguddannet F',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Polo GTI',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Corona lock-down, slotsturer, hjemmekontor',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Første boligkøb sammen',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Lyngby',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Forlovelse',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
  {
    title: 'Bryllup',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at justo vel purus laoreet molestie eu eu purus. Ut ornare ornare ante, mollis bibendum ligula cursus eu.',
    date: 'Oktober 2021',
  },
]

const TimelinePage = () => {
  const isMobile = useIsMobile()
  const [timeLineItemsS3, setTimelineItemsS3] = useState<TimelinePageItem[]>([])

  useEffect(() => {
    const getData = async () => {
      const mapped = await Promise.all(
        timelineItems.map(async (item) => {
          return {
            ...item,
            imgUrl: await getImgS3Url(item.imgUrl || 'raadvad.jpg'),
          } as TimelinePageItem
        })
      )

      setTimelineItemsS3(mapped)
    }

    getData()
  }, [])

  return (
    <>
      <Typography variant="h2">Tidslinje</Typography>
      <Typography variant="h4">
        Dette er et overfladisk gennemgang af hvordan vi 10 år senere står og
        skal giftes i Lyngby. Dyk ned i historien!
      </Typography>
      <Box mb={8} />

      {isMobile && (
        <Stack
          divider={
            <Divider variant="middle">
              <TimelineDot color="primary">
                <CalendarTodayIcon />
              </TimelineDot>
            </Divider>
          }
          spacing={2}
        >
          {timeLineItemsS3.map((item, index) => (
            <Outline>
              <Typography variant="h6" color="primary">
                {item.date}
              </Typography>

              <Typography variant="subtitle2">{item.title} </Typography>

              <ImgCarousel height={isMobile ? '150px' : '250px'}>
                {[<TimelineImg imgUrl={item.imgUrl || 'raadvad.jpg'} />]}
              </ImgCarousel>

              <Typography variant="body2">{item.text}</Typography>
            </Outline>
          ))}
        </Stack>
      )}

      {!isMobile && (
        <Timeline position="alternate">
          {timeLineItemsS3.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent variant="body2" color="text.secondary">
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={2}
                >
                  <Typography textAlign="center" variant="h4" color="primary">
                    {item.title}
                  </Typography>
                  <Typography variant="h6">{item.date}</Typography>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  <CalendarTodayIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ px: 2 }}>
                <Outline textAlign="center" p={2}>
                  <ImgCarousel height="250px">
                    {[<TimelineImg imgUrl={item.imgUrl || 'raadvad.jpg'} />]}
                  </ImgCarousel>

                  <Typography variant="body1" component="span">
                    {item.text}
                  </Typography>
                </Outline>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </>
  )
}

export default TimelinePage
